import { useEffect, useState } from 'react';
import OpenLogin from "@toruslabs/openlogin";

function App() {
  const [openlogin, setOpenlogin] = useState(null);
  const [privateKey, setPrivateKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    initializeOpenLogin();
  }, []);

  const initializeOpenLogin = async () => {
    try {
      const openlogin = new OpenLogin({
        clientId: "BLSzuAhm0FNzh9lKpe2_IsIM-EFptN3g3N4tVF_WiaAOnF1qwZKs2-i92oYMwh0WiS5_0DjnMOrBXx4iHYCN9l4",
        network: "testnet"
      });

      await openlogin.init();
      setOpenlogin(openlogin);

      console.log(openlogin);
      console.log("openlogin.privKey");
      console.log(openlogin.privKey);

      setPrivateKey(openlogin.privKey);

      const userInfo = await openlogin.getUserInfo();
      console.log("userInfo");
      console.log(userInfo);
      console.log(userInfo.idToken);
      setAccessToken(userInfo.idToken);
    } catch (err) {
      setError('Ошибка инициализации OpenLogin');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    try {
      if (!openlogin) return;

      const userInfo = await openlogin.login({
        loginProvider: "google",
        redirectUrl: window.location.origin, // Редирект на текущий домен
      });

      setAccessToken(userInfo.idToken);
      setPrivateKey(openlogin.privKey);
    } catch (err) {
      setError('Ошибка при входе');
      console.error(err);
    }
  };

  const handleLogout = async () => {
    try {
      if (!openlogin) return;

      await openlogin.logout();
      setPrivateKey(null);
      setAccessToken(null);
    } catch (err) {
      setError('Ошибка при выходе');
      console.error(err);
    }
  };

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="container" style={{ padding: '20px' }}>
      <h1>OpenLogin Тест</h1>

      {error && (
        <div style={{ color: 'red', marginBottom: '20px' }}>{error}</div>
      )}

      {accessToken ? (
        <div>
          <p>Пользователь авторизован</p>
          <p>Access Token: {accessToken}</p>
          <button onClick={handleLogout}>Выйти</button>
        </div>
      ) : (
        <div>
          <p>Пользователь не авторизован</p>
          <button onClick={handleLogin}>Войти через Google</button>
        </div>
      )}
    </div>
  );
}

export default App;